<template>
  <v-container>
    <v-col>
      <v-row justify="end">
        <v-btn icon @click="dialog_buscador = true" v-on:click="Getalltask">
          <v-icon
            :size="icon_size"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mt-2'
                : $vuetify.breakpoint.xl
                ? 'mr-6 mt-2'
                : 'mr-n16 mt-2'
            "
            >mdi-magnify</v-icon
          >
        </v-btn>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'ml-n1 mr-0 mt-1'
                  : 'ml-n1 mr-n16 mt-1'
              "
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? ''
                    : $vuetify.breakpoint.xl
                    ? 'mr-6'
                    : 'mr-n16'
                "
                :size="icon_size"
                >mdi-dots-vertical</v-icon
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="dialog_exportar = true">
              <v-list-item-title>Exportar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn absolute left icon class="ml-8" v-on:click="go_to_projects">
          <v-icon :size="icon_size">mdi-chevron-left </v-icon> Proyectos
        </v-btn>
      </v-row>

      <h1
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'text-center text-h5 pt-1'
            : 'text-center text-h4 pb-2 mt-n8'
        "
      >
        {{ project_name }}
      </h1>
    </v-col>
    <v-row class="fill-height">
      <v-col>
        <v-toolbar
          class="ml-auto mr-auto rounded-t-lg blue lighten-3"
          max-width="1000"
          flat
        >
          <v-btn
            outlined
            class="ml-4 mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-sheet
          class="ml-auto mr-auto"
          :height="calendar_size"
          max-width="1000"
        >
          <v-calendar
            class="blue lighten-5"
            ref="calendar"
            v-model="focus"
            color="primary"
            :type="type"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-card class="mx-auto mt-9 rounded-t-lg" max-width="600" tile>
      <v-card-title class="white--text blue darken-2 py-2">
        Tasks
        <v-btn
          absolute
          right
          class="mr-n7 mt-n8"
          fab
          height="48"
          width="48"
          color="white"
          @click="dialog = true"
        >
          <v-icon color="blue">fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <scroll-container
      :style="$vuetify.breakpoint.xl ? 'margin-right: -17px;' : ''"
    >
      <v-card
        class="mx-auto rounded-b-lg blue lighten-5"
        max-width="600"
        :height="
          $vuetify.breakpoint.xs ? 215 : $vuetify.breakpoint.xl ? 275 : 200
        "
      >
        <v-list-item
          three-line
          v-for="(tasks, index) in filteredtasks"
          :key="index"
          @click="dialog_buscador = false"
          v-on:click="Gettask(tasks._id)"
          :style="task_color[index]"
          light
          class="font-weight-bold mb-1 rounded-lg"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ tasks.name }}-{{ tasks.responsable }}</v-list-item-title
            >
            <v-list-item-subtitle>
              From: {{ tasks.start_date.slice(0, 10) }} to
              {{ tasks.end_date.slice(0, 10) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <div v-if="tasks.status == 0">
                <v-icon color="blue">far fa-pause-circle</v-icon> StandBy
              </div>
              <div v-else-if="tasks.status == 1">
                <v-icon color="white">fas fa-spinner</v-icon> In Progress
              </div>
              <div v-else-if="tasks.status == 2">
                <v-icon color="red darken-2">far fa-clock</v-icon> Delayed
              </div>
              <div v-else-if="tasks.status == 3">
                <v-icon color="green">fas fa-clipboard-check</v-icon> Finished
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </scroll-container>

    <v-dialog
      content-class="my-custom-dialog"
      v-model="edittask"
      max-width="700px"
      class="mt-n5"
    >
      <v-sheet height="640">
        <v-card class="rounded-lg grey lighten-2">
          <v-row class="text-center pa-4" justify="center">
            <v-card-title>
              <span class="text-h5 mt-5">Visualizador de tareas</span>
            </v-card-title>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn absolute right icon v-bind="attrs" v-on="on">
                  <v-icon class="mt-2 mr-n5">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="(disable = false), (show_btn = false)">
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="delete_task_dialog = true">
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn absolute left icon class=" ml-n3">
              <v-icon size="20" @click="edittask = false">mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-col cols="12">
                    <v-row>
                      <v-date-picker
                        :disabled="disable"
                        v-model="dates_v"
                        range
                        no-title
                      ></v-date-picker>
                      <v-col cols="12" sm="6" md="12">
                        <v-icon>mdi-calendar</v-icon> From {{ dates_v[0] }} To
                        {{ dates_v[1] }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    required
                    :items="items"
                    item-text="title"
                    return-object
                    v-model="current_s"
                    label="Estado de la tarea"
                  />
                  <v-text-field
                    :disabled="disable"
                    v-model="current_name"
                    label="Nombre de la tarea"
                    required
                  ></v-text-field>
                  <v-text-field
                    :disabled="disable"
                    v-model="current_r"
                    label="Nombre del responsable"
                    required
                  ></v-text-field>
                  <v-text-field
                    :disabled="disable"
                    v-model="current_description"
                    label="Descripción"
                    hint="Breve descripción de la tarea"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="8" no-gutters>
                <v-list class="grey lighten-2">
                  <v-list-item
                    v-for="(resource, index) in current_resource"
                    :key="index"
                  >
                    <v-text-field
                      class="ml-n10"
                      :disabled="disable"
                      v-model="recurso_req_nombre[index]"
                      label="Recurso"
                    ></v-text-field>
                    <v-text-field
                      class="md-2 "
                      :disabled="disable"
                      type="number"
                      v-model="recurso_req_cantidad[index]"
                      label="Recursos Requeridos"
                    >
                    </v-text-field>
                    <v-text-field
                      class=" mr-n5 ml-2"
                      type="number"
                      v-model="recurso_real_cant[index]"
                      label="Recursos Reales"
                    >
                    </v-text-field>
                    <v-icon
                      v-if="!disable"
                      @click="
                        recurso_real_cant.splice(index, 1),
                          recurso_req_cantidad.splice(index, 1),
                          recurso_req_nombre.splice(index, 1),
                          current_resource.splice(index, 1)
                      "
                      >mdi-delete</v-icon
                    >
                  </v-list-item>
                </v-list>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-text-field
                        v-if="!disable"
                        required
                        label="Nombre de recurso"
                        outlined
                        v-model="Resource_name"
                      ></v-text-field>
                      <v-text-field
                        v-if="!disable"
                        required
                        min="1"
                        type="number"
                        label="Cantidad del recurso"
                        outlined
                        v-model="Resource_amount"
                        @keypress="isNumber($event)"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-btn
                    class="mb-7"
                    v-if="!disable"
                    color="green"
                    dark
                    :disabled="!valid"
                    v-on:click="new_resource(1)"
                    block
                  >
                    <v-icon>fas fa-plus</v-icon> Agregar recurso
                  </v-btn>
                </v-form>
              </v-col>

              <v-card-actions>
                <v-row justify="space-between" class="px-6">
                  <v-btn color="error" @click="edittask = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="show_btn"
                    color="blue darken-1"
                    v-on:click="updatetask"
                  >
                    Guardar
                  </v-btn>
                  <v-btn
                    v-if="!show_btn"
                    color="blue darken-1"
                    v-on:click="save_edit_task"
                  >
                    Guardar
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
        content-class="my-custom-dialog"
        v-model="dialog"
        max-width="700px"
      >
        <v-sheet max-height="640" class="grey lighten-2">
          <v-card class="rounded-lg grey lighten-2">
            <v-btn absolute left icon class="ml-n3 mt-1 mb-n5">
              <v-icon size="20" @click="dialog = false">mdi-close</v-icon>
            </v-btn>
            <v-card-title class="justify-center pt-8">
              <span class="text-h5">Creación de tareas</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form v-model="valid1">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-col cols="12">
                        <v-row>
                          <v-date-picker
                            v-model="dates"
                            range
                            no-title
                          ></v-date-picker>
                          <v-col cols="12" sm="6" md="12">
                            <v-icon>mdi-calendar</v-icon> From {{ dates[0] }} To
                            {{ dates[1] }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :rules="reglas_guardar"
                        v-model="name"
                        label="Nombre de la tarea"
                        required
                      ></v-text-field>
                      <v-text-field
                        :rules="reglas_guardar"
                        v-model="responsable"
                        label="Nombre del responsable"
                        required
                      ></v-text-field>
                      <v-text-field
                        :rules="reglas_guardar"
                        v-model="description"
                        label="Descripción"
                        hint="Breve descripción de la tarea"
                      ></v-text-field>

                      <v-list class="ml-n4 grey lighten-2">
                        <v-list-item
                          v-for="(resource, index) in recursos"
                          :key="index"
                        >
                          <v-text-field
                            required
                            label="Nombre de recurso"
                            v-model="recursos_edit_name[index]"
                          ></v-text-field>
                          <v-text-field
                            class="ml-4"
                            label="Cantidad"
                            v-model="recursos_edit_cant[index]"
                          ></v-text-field>
                          <v-icon
                            @click="
                              recursos_edit_name.splice(index, 1),
                                recursos_edit_cant.splice(index, 1),
                                recursos.splice(index, 1)
                            "
                            >mdi-delete</v-icon
                          >
                        </v-list-item>
                      </v-list>
                      <v-form v-model="valid">
                        <v-row>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                            :rules="reglas_guardar"
                              required
                              label="Nombre de recurso"
                              outlined
                              v-model="Resource_name"
                            ></v-text-field>
                            <v-text-field
                            :rules="reglas_guardar"
                              required
                              min="1"
                              type="number"
                              label="Cantidad del recurso"
                              outlined
                              v-model="Resource_amount"
                              @keypress="isNumber($event)"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-btn
                          color="green"
                          dark
                          :disabled="!valid"
                          v-on:click="new_resource(0)"
                          block
                        >
                          <v-icon>fas fa-plus</v-icon> Agregar recurso
                        </v-btn>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-row justify="space-between" class="pa-6">
                <v-btn color="error" @click="dialog = false">
                  Cancelar
                </v-btn>

                <v-btn
                  :disabled="!valid1"
                  color="blue darken-1"
                  @click="Getalltask()"
                  v-on:click="newtask"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-dialog>
    </v-row>
    <v-dialog v-model="dialog1" width="350">
      <v-card class="text-center" style=" border-radius: 12px;">
        <v-card-title class="text-h4 grey darken-1 justify-center">
          Invalid
        </v-card-title>

        <v-card-text class="text-h6">
          Name already in use!
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="error"
            outlined="true"
            text
            @click="dialog1 = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_task_dialog" width="350">
      <v-card class="text-center" style=" border-radius: 12px;">
        <v-card-title class="text-h4 blue-grey justify-center">
          Eliminar
        </v-card-title>

        <v-card-text class="text-h6">
          ¿Está seguro de que desea eliminar esta tarea?
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="blue darken-1"
            text
            @click="delete_task_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="error"
            text
            v-on:click="delete_task"
          >
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_buscador" width="800">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-card-title>
          Buscador
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          class="grey lighten-2"
          :headers="headers_buscador"
          :items="task_list"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn v-on:click="Gettask(item.id)">
              <v-icon small class="mr-2">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_exportar" max-width="700px">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-card-title class="justify-center">
          <span class="text-h5 ">Exportar</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid1">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-col cols="12">
                    <v-row>
                      <v-date-picker
                        :rules="reglas_exportar"
                        v-model="dates"
                        range
                        no-title
                      ></v-date-picker>
                      <v-col cols="12" sm="6" md="12">
                        <v-icon>mdi-calendar</v-icon> From {{ dates[0] }} To
                        {{ dates[1] }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :rules="reglas_exportar"
                    v-model="place_holder"
                    label="Nombre"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-between" class="pa-6">
            <v-btn color="error" @click="dialog_exportar = false">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!valid1"
              color="blue darken-1"
              @click.prevent="GetReport()"
            >
              Exportar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_reporte_entrar" width="700">
      <v-card
        elevation="24"
        style=" border-radius: 12px;"
        class="grey lighten-2"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-center py-2 pt-6">
              <v-btn absolute left icon class="ml-n4 mt-n6">
                <v-icon size="20" @click="dialog_reporte_entrar = false"
                  >mdi-close</v-icon
                >
              </v-btn>
              <h1>Aviso</h1>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <v-col col="7" md="9">
              <h3 v-if="delayed_tasks_aviso.length > 0" class="text-center">
                Tareas con atraso:
              </h3>
              <v-list class="grey lighten-2">
                <v-list-item
                  three-line
                  v-for="(tasks, index) in delayed_tasks_aviso"
                  :key="index"
                  @click="dialog_buscador = false"
                  v-on:click="Gettask(tasks._id)"
                  :style="tasks[1]"
                  light
                  class="font-weight-bold mb-1 rounded-lg"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ tasks[0].name }}-{{
                        tasks[0].responsable
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      From: {{ tasks[0].start_date.slice(0, 10) }} to
                      {{ tasks[0].end_date.slice(0, 10) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div>
                        <v-icon color="red darken-2">far fa-clock</v-icon>
                        Delayed
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <h3 v-if="hoy_tasks_aviso.length > 0" class="text-center">
                Esto es para hoy:
              </h3>
              <v-list class="grey lighten-2">
                <v-list-item
                  three-line
                  v-for="(tasks, index) in hoy_tasks_aviso"
                  :key="index"
                  @click="dialog_buscador = false"
                  v-on:click="Gettask(tasks._id)"
                  :style="task_color_aviso[index]"
                  light
                  class="font-weight-bold mb-1 rounded-lg"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ tasks.name }}-{{
                        tasks.responsable
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      From: {{ tasks.start_date.slice(0, 10) }} to
                      {{ tasks.end_date.slice(0, 10) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div v-if="tasks.status == 0">
                        <v-icon color="blue">far fa-pause-circle</v-icon>
                        StandBy
                      </div>
                      <div v-else-if="tasks.status == 1">
                        <v-icon color="white">fas fa-spinner</v-icon> In
                        Progress
                      </div>
                      <div v-else-if="tasks.status == 2">
                        <v-icon color="yellow">far fa-clock</v-icon> Delayed
                      </div>
                      <div v-else-if="tasks.status == 3">
                        <v-icon color="green">fas fa-clipboard-check</v-icon>
                        Finished
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <br />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style scoped>
scroll-container {
  display: block;
  height: "$vuetify.breakpoint.xs ? 220 : 500";
  overflow-y: scroll;
  scroll-behavior: auto;
}
.my-custom-dialog {
  align-self: flex-start;
  border-radius: 12px;
}
</style>
<script>
/* eslint-disable */
import FileSaver from "file-saver";
import axios from "axios";
import Task_FoldersVue from "./Task_Folders.vue";
export default {
  name: "HelloWorld",

  data: () => ({
    file_id: 0,
    auth_user: {},
    token_s: "",
    index_resources: [],
    recurso_real_cant: [],
    recurso_req_cantidad: [],
    recurso_req_nombre: [],
    current_id: "",
    place_holder: "",
    user_id: "",
    current_description: "",
    reglas_guardar: [(v) => !!v || "requerido para Guardar"],
    reglas_exportar: [(v) => !!v || "requerido para exportar"],
    alltask: [],
    startdate: "",
    enddate: "",
    search: "",
    sdate: "",
    edate: "",
    valid: true,
    valid2: true,
    dialog1: false,
    current_s: "",
    items: [
      { title: "Stand by" },
      { title: "In progress" },
      { title: "Delayed" },
      { title: "Finished" },
    ],
    Resource_name: "",
    Resource_amount: 0,
    recursos: [],
    current_resource: [],
    status: 0,
    responsable: "",
    current_r: "",
    name: "",
    current_name: "",
    dates: ["", ""],
    dates_sd: "",
    dates_ed: "",
    dates_v: [],
    valid1: true,
    disable: true,
    description: "",
    edittask: false,
    project_name: "",
    dialog: false,
    dialog_buscador: false,
    dialog_exportar: false,
    month_selected: 0,
    dialog_reporte_entrar: false,
    delete_task_dialog: false,
    show_btn: true,
    statuso: "",
    organization: "DPEI",
    focus: "",
    type: "month",
    recursos_edit_name: [],
    recursos_edit_cant: [],
    task_color_aviso: [],
    search_list: [],
    headers_buscador: [
      {
        text: "Task Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Start Date", value: "start" },
      { text: "Responsable", value: "responsable" },
      { text: "End Date", value: "end" },
      { text: "Status", value: "status" },
      { text: "Editar", value: "actions", sortable: false },
    ],
    task_list: [],
    selectedElement: null,
    selectedOpen: false,
    list_num: 0,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    delayed_tasks_aviso: [],
    hoy_tasks_aviso: [],
  }),

  mounted() {
    this.Getalltask();
    this.focus =
      new Date().toLocaleDateString("en-GB").slice(6, 10) +
      "-" +
      new Date().toLocaleDateString("en-GB").slice(3, 5) +
      "-" +
      new Date(Date.now()).getDate();
    this.parseJwt();
    this.$refs.calendar.checkChange();
  },
  computed: {
    icon_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 24;
        case "sm":
          return 24;
        case "md":
          return 30;
        case "lg":
          return 30;
        case "xl":
          return 30;
      }
    },
    calendar_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 230;
        case "sm":
          return 230;
        case "md":
          return 300;
        case "lg":
          return 300;
        case "xl":
          return 400;
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    filteredtasks() {
      let filtered = [];
      this.task_color = [];
      let count = 0;
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;
      this.alltask.forEach((task) => {
        var currentdayselected = this.focus;
        var currentdayselected = new Date(currentdayselected);

        var currentday = new Date(currentdayselected);
        var taskendday = new Date(task.end_date);
        var taskstartday = new Date(task.start_date);

        var current_month = currentdayselected.getMonth();
        var currentdayselected = currentdayselected.getDate() + 1;
        var taskstartdate = task.start_date;
        var taskstartdate = new Date(taskstartdate);
        var task_month = taskstartdate.getMonth();
        var taskstartdate = taskstartdate.getDate() + 1;
        //Organizador de tasks y su color
        if (
          currentday <= taskendday && currentday >= taskstartday &&
          task.status == 2 &&
          task_month == current_month
        ) {
          //Delayed
          this.task_color.splice(count, 0, "background-color: #EF9A9A");
          filtered.splice(count, 0, task);
          count += 1;
          count1 += 1;
          count2 += 1;
          count3 += 1;
        } else if (
          currentday <= taskendday && currentday >= taskstartday &&
          task.status == 1 &&
          task_month == current_month
        ) {
          //inprogress
          this.task_color.splice(count1, 0, "background-color: #90CAF9");
          filtered.splice(count1, 0, task);
          count1 += 1;
          count2 += 1;
          count3 += 1;
        } else if (
          currentday <= taskendday && currentday >= taskstartday &&
          task.status == 0 &&
          task_month == current_month
        ) {
          //Standby
          this.task_color.splice(count2, 0, "background-color: #CFD8DC");
          filtered.splice(count2, 0, task);
          count2 += 1;
          count3 += 1;
        } else if (
          currentday <= taskendday && currentday >= taskstartday &&
          task.status == 3 &&
          task_month == current_month
        ) {
          //finished
          this.task_color.splice(count3, 0, "background-color: #A5D6A7");
          filtered.splice(count3, 0, task);
          count3 += 1;
        }
      });
      return filtered;
    },
  },
  created() { 
    this.inverval = setInterval(() => this.parseJwt(), 60000);
    this.file_id = this.$route.params.id;
    this.project_name = this.$route.query.name;
    this.list_num = this.$route.query.list;
  },

  methods: {
    go_to_projects() {
      window.location.replace("/task_folders");
    },
    save_edit_task() {
      if (this.current_s.title == "Stand by") {
        this.statuso = 0;
      } else if (this.current_s.title == "In progress") {
        this.statuso = 1;
      } else if (this.current_s.title == "Delayed") {
        this.statuso = 2;
      } else if (this.current_s.title == "Finished") {
        this.statuso = 3;
      }
      let vueInstance = this;
      let ID = this.current_id;
      let url = process.env.VUE_APP_ROOT_URL + "task/update/" + ID;
      var token = sessionStorage.getItem("token");

      var rs = [];
      for (var i = 0; i < this.current_resource.length; i++) {
        rs.push({
          [this.recurso_req_nombre[i]]: this.recurso_real_cant[i][0],
        });
      }
      var rs_req = [];
      for (var i = 0; i < this.current_resource.length; i++) {
        rs_req.push({
          [this.recurso_req_nombre[i]]: this.recurso_req_cantidad[i][0],
        });
      }

      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: token,

          "Content-Type": "application/json",
        },
        data: {
          name: this.current_name,
          organization: this.organization,
          responsable: this.current_r,
          description: this.current_description,
          status: this.statuso,
          require_resourses: rs_req,
          real_resourses: rs,
          start_date: this.dates_v[0],
          end_date: this.dates_v[1],
        },
      })
        .then(function(response) {
          let link =
            "/task_manager/" +
            vueInstance.file_id +
            "?name=" +
            vueInstance.project_name +
            "&aviso=" +
            1 +
            "&list=" +
            vueInstance.list_num;
          window.location.replace(link);
        })
        .catch(function(error) {
          console.log(error);
          vueInstance.dialog1 = true;
        });
    },
    GetReport() {
      let url =
        process.env.VUE_APP_ROOT_URL +
        "task/report/" +
        this.file_id +
        "/" +
        this.dates[0] +
        "/" +
        this.dates[1] +
        "/" +
        this.place_holder;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },

        responseType: "blob",
      })
        .then(function(response) {
          FileSaver.saveAs(response.data, vueInstance.place_holder);
          vueInstance.place_holder = "";
          vueInstance.dates = [];
          vueInstance.dialog_exportar = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    delete_task() {
      this.delete_task_dialog = false;
      for (let i = 0; i < this.alltask.length; i++) {
        if (this.current_id == this.alltask[i]._id) {
          this.alltask.splice(i, 1);
          // console.log(this.alltask);
        }
      }
      let url =
        process.env.VUE_APP_ROOT_URL + "task/dump/one/" + this.current_id;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "DELETE",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          vueInstance.edittask = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    updatetask() {
      let vueInstance = this;
      if (this.current_s.title == "Stand by") {
        this.statuso = 0;
      } else if (this.current_s.title == "In progress") {
        this.statuso = 1;
      } else if (this.current_s.title == "Delayed") {
        this.statuso = 2;
      } else if (this.current_s.title == "Finished") {
        this.statuso = 3;
      }
      var ID = this.current_id;
      let url = process.env.VUE_APP_ROOT_URL + "task/interact/" + ID;
      var token = sessionStorage.getItem("token");

      var rs = [];
      for (var i = 0; i < this.current_resource.length; i++) {
        rs.push({
          [Object.keys(this.current_resource[i])[0]]: this.recurso_real_cant[
            i
          ][0],
        });
      }
      axios({
        method: "PATCH",
        url: url,
        headers: { Authorization: token },
        data: {
          status: this.statuso,
          real_resourses: rs,
        },
      })
        .then(function(response) {
          let link =
            "/task_manager/" +
            vueInstance.file_id +
            "?name=" +
            vueInstance.project_name +
            "&aviso=" +
            1 +
            "&list=" +
            vueInstance.list_num;
          window.location.replace(link);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    parseJwt(token = this.token_s) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      this.auth_user = JSON.parse(jsonPayload);
      var date = new Date();
      var date = date.getTime();
      var date_e = new Date(0);
      date_e.setUTCSeconds(this.auth_user.exp);
      var date_e = date_e.getTime();
      //console.log(date_e);
      let parsed_data = JSON.parse(jsonPayload);
      //console.log(parsed_data);
      this.user_id = parsed_data.userId;

      //console.log(date);
      if (date_e <= date) {
        var session_key = sessionStorage.getItem("session_key");
        axios({
          method: "POST",
          url: process.env.VUE_APP_ROOT_URL + "user/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: parsed_data.email,
            session_key: session_key,
          },
        })
          .then(function(response) {
            sessionStorage.clear();
            var token = `Bearer ${response.data.token}`;
            var session_key = response.data.session_key;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("session_key", session_key);
          })
          .catch(function(error) {
            sessionStorage.clear();
            window.location.replace("/login");
            console.log(error);
          });
      }
    },
    Gettask(x) {
      this.current_id = x;
      this.dialog_buscador = false;
      this.edittask = true;
      var ID = this.current_id;
      let url = process.env.VUE_APP_ROOT_URL + "task/one/" + ID;
      var token = sessionStorage.getItem("token");

      var vueInstance = this;

      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }).then(function(response) {
        vueInstance.current_name = response.data.message.name;
        vueInstance.current_r = response.data.message.responsable;
        vueInstance.current_description = response.data.message.description;
        vueInstance.dates_sd = response.data.message.start_date;
        vueInstance.dates_ed = response.data.message.end_date;
        vueInstance.dates_v = [
          vueInstance.dates_sd.slice(0, 10),
          vueInstance.dates_ed.slice(0, 10),
        ];
        vueInstance.current_resource = response.data.message.require_resourses;
        vueInstance.index_resources = response.data.message.real_resourses;
        vueInstance.organize_resources(response.data.message.status);
      });
    },
    organize_resources(stat) {
      this.recurso_real_cant = [];
      this.recurso_req_cantidad = [];
      this.recurso_req_nombre = [];
      this.statuso = stat;

      for (let i = 0; i < this.index_resources.length; i++) {
        this.recurso_real_cant.push(Object.values(this.index_resources[i]));
      }

      for (let i = 0; i < this.current_resource.length; i++) {
        this.recurso_req_cantidad.push(Object.values(this.current_resource[i]));
      }

      for (let i = 0; i < this.current_resource.length; i++) {
        this.recurso_req_nombre.push(Object.keys(this.current_resource[i]));
      }
    },

    Getalltask() {
      Storage.prototype.setObj = function(key, obj) {
        return this.setItem(key, JSON.stringify(obj));
      };
      Storage.prototype.getObj = function(key) {
        return JSON.parse(this.getItem(key));
      };
      var date1 = new Date();
      date1 = date1.getTime();
      var last_update = 0;
      let aviso = this.$route.query.aviso;
      if (sessionStorage.getItem("lastupdate") == "0") {
        last_update = 0;
      } else if (this.month != 0) {
        last_update = 0;
      } else {
        last_update = sessionStorage.getItem("lastupdate");
      }
      // console.log(last_update);

      var date = new Date();
      var firstDay = new Date(
        date.getFullYear(),
        date.getMonth() + this.month_selected,
        0
      ).getTime();
      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + (1 + this.month_selected),
        0
      ).getTime();

      let url =
        process.env.VUE_APP_ROOT_URL +
        "task/all/" +
        this.file_id +
        "/" +
        firstDay +
        "/" +
        lastDay +
        "/" +
        last_update;
      var token = sessionStorage.getItem("token");
      this.token_s = token;
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }).then(function(response) {
        // console.log(response.data);
        sessionStorage.setItem("lastupdate", date1);

        if (vueInstance.alltask.length < 1) {
          for (let i = 0; i < response.data.tasks.length; i++) {
            if (response.data.tasks[i] != null) {
              vueInstance.alltask.push(response.data.tasks[i]);
              vueInstance.search_list.push(response.data.tasks[i]);
            }
          }
          sessionStorage.setObj(
            "alltask" + vueInstance.list_num,
            vueInstance.alltask
          );
        } else {
          aviso = 1;
          vueInstance.alltask = sessionStorage.getObj(
            "alltask" + vueInstance.list_num
          );
          for (let i = 0; i < response.data.tasks.length; i++) {
            var already_in_list = false;
            if (response.data.tasks[i] != null) {
              for (let z = 0; z < vueInstance.alltask.length; z++) {
                if (response.data.tasks[i]._id == vueInstance.alltask[z]._id) {
                  already_in_list = true;
                }
              }
              if (already_in_list == false) {
                vueInstance.alltask.push(response.data.tasks[i]);
                vueInstance.search_list.push(response.data.tasks[i]);
              }
            }
          }
          sessionStorage.setObj(
            "alltask" + vueInstance.list_num,
            vueInstance.alltask
          );
        }
        console.log("correct");
        vueInstance.organize_tasks(vueInstance.search_list);

        if (aviso == 0) {
          vueInstance.filter_task_aviso();
        }
      });
    },
    organize_tasks(lis) {
      this.task_list = [];
      for (let i = 0; i < lis.length; i++) {
        var stat = "";
        if (lis[i].status == 0) {
          var stat = "Stand By";
        } else if (lis[i].status == 1) {
          var stat = "In Progress";
        } else if (lis[i].status == 2) {
          var stat = "Delayed";
        } else {
          var stat = "Finished";
        }
        this.task_list.push({
          name: lis[i].name,
          start: lis[i].start_date,
          responsable: lis[i].responsable,
          end: lis[i].end_date,
          status: stat,
          id: lis[i]._id,
        });
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    new_resource(x) {
      if (x == 0) {
        let data = { [this.Resource_name]: this.Resource_amount };
        this.recursos.push(data);
        this.recursos_edit_name.push(this.Resource_name);
        this.recursos_edit_cant.push(this.Resource_amount);
        this.Resource_name = "";
        this.Resource_amount = 0;
      } else {
        let data = { [this.Resource_name]: this.Resource_amount };
        this.current_resource.push(data);
        this.organize_resources(this.statuso);
        this.Resource_name = "";
        this.Resource_amount = 0;
      }
    },
    newtask() {
      let vueInstance = this;
      if (this.dateRangeText.length == 23) {
        this.startdate = this.dateRangeText.slice(0, 10);
        this.enddate = this.dateRangeText.slice(13, 23);
      } else if (this.dateRangeText.length == 10) {
        this.startdate = this.dateRangeText.slice(0, 10);
        this.enddate = this.startdate;
      } else {
        console.log("error");
        return;
      }
      this.recursos = [];
      for (var i = 0; i < this.recursos_edit_name.length; i++) {
        this.recursos.push({
          [this.recursos_edit_name[i]]: this.recursos_edit_cant[i],
        });
      }
      this.recursos_reales = [];
      for (var i = 0; i < this.recursos_edit_name.length; i++) {
        this.recursos_reales.push({
          [this.recursos_edit_name[i]]: 0,
        });
      }
      let url = process.env.VUE_APP_ROOT_URL + "task/send";
      var token = sessionStorage.getItem("token");
      this.sdate = new Date(this.startdate);
      this.sdate = this.sdate.getTime();
      this.edate = new Date(this.enddate);
      this.edate = this.edate.getTime();
      axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: {
          name: this.name,
          project: this.file_id,
          responsable: this.responsable,
          description: this.description,
          require_resourses: this.recursos,

          real_resourses: this.recursos_reales,
          start_date: this.sdate,
          end_date: this.edate,
        },
      })
        .then(function(response) {
          // console.log(response);
          let link =
            "/task_manager/" +
            vueInstance.file_id +
            "?name=" +
            vueInstance.project_name +
            "&aviso=" +
            1 +
            "&list=" +
            vueInstance.list_num;
          window.location.replace(link);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.month_selected = this.month_selected - 1;
      this.Getalltask();
      this.$refs.calendar.prev();
    },
    next() {
      this.month_selected = this.month_selected + 1;
      this.Getalltask();
      this.$refs.calendar.next();
    },
    filter_task_aviso() {
      this.delayed_tasks_aviso = [];
      this.hoy_tasks_aviso = [];
      this.task_color_aviso = [];
      let count = 0;
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;

      this.alltask.forEach((task) => {
        var currentday = new Date();
        //var currentday = currentday.getDate() + 1;
        var taskenddate = task.end_date;
        var taskenddate = new Date(task.end_date);

        if (task.status == 2) {
          this.delayed_tasks_aviso.push([task, "background-color: #EF9A9A"]);
        }

        //Organizador de tasks y su color
        if (
          taskenddate.getDate() + 1 == currentday.getDate() &&
          taskenddate.getMonth() == currentday.getMonth() &&
          task.status == 2
        ) {
          //delayed
          //this.task_color_aviso.splice(count, 0, "background-color: #E57373");
          //this.hoy_tasks_aviso.splice(count, 0, task);
          count += 1;
          count1 += 1;
          count2 += 1;
          count3 += 1;
        } else if (
          taskenddate.getDate() + 1 == currentday.getDate() &&
          taskenddate.getMonth() == currentday.getMonth() &&
          task.status == 1
        ) {
          //inprogress
          this.task_color_aviso.splice(count1, 0, "background-color: #90CAF9");
          this.hoy_tasks_aviso.splice(count1, 0, task);
          count1 += 1;
          count2 += 1;
          count3 += 1;
        } else if (
          taskenddate.getDate() + 1 == currentday.getDate() &&
          taskenddate.getMonth() == currentday.getMonth() &&
          task.status == 0
        ) {
          //standby
          this.task_color_aviso.splice(count2, 0, "background-color: #CFD8DC");
          this.hoy_tasks_aviso.splice(count2, 0, task);
          count2 += 1;
          count3 += 1;
        } else if (
          taskenddate.getDate() + 1 == currentday.getDate() &&
          taskenddate.getMonth() == currentday.getMonth() &&
          task.status == 3
        ) {
          //finished
          this.task_color_aviso.splice(count3, 0, "background-color: #A5D6A7");
          this.hoy_tasks_aviso.splice(count3, 0, task);
          count3 += 1;
        }
        if (
          this.hoy_tasks_aviso.length > 0 ||
          this.delayed_tasks_aviso.length > 0
        ) {
          this.dialog_reporte_entrar = true;
        }
      });
    },
  },
};
</script>
