<template>
  <v-app style="background-image: linear-gradient(360deg, #90A4AE, #ECEFF1);">
    <v-footer absolute>
      <v-bottom-navigation app color="teal" grow v-if="isTask">
        <v-btn icon selected v-on:click="Project_page">
          <span>Reportes</span>

          <v-icon>mdi-file-document</v-icon>
        </v-btn>
        <v-btn icon selected v-on:click="Task_page">
          <span>Tareas</span>

          <v-icon>fas fa-book</v-icon>
        </v-btn>
        <v-btn icon selected v-on:click="User_page">
          <span>Usuarios</span>

          <v-icon>mdi-account</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
    <v-main>
      <v-container fluid>
        <router-view> </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
export default {
  name: "App",

  data: () => ({}),
  computed: {
    isTask() {
      return (
        this.$route.name === "Task_manager" ||
        this.$route.name === "Users" ||
        this.$route.name === "Projects" ||
        this.$route.name === "Files" ||
        this.$route.name === "Task_Folders"
      );
    },
  },
  methods: {
    Task_page() {
      window.location.replace("/task_folders");
    },
    User_page() {
      window.location.replace("/Users");
    },
    Project_page() {
      window.location.replace("/projects");
    },
    info_Pull() {
      var token = sessionStorage.getItem("token");
    },
  },
};
</script>
