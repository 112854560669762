<template>
  <v-container>
    <v-app-bar
      fixed
      flat
      height="150"
      class="pt-3"
      style="background-image: linear-gradient(360deg, #dae0e3, #f6f7f7);"
    >
      <v-col>
        <v-row justify="end">
          <v-btn class="mr-n3" icon @click="dialog_agregar = true">
            <v-icon :size="icon_size">mdi-plus</v-icon>
          </v-btn>
          <v-btn class="mr-n4" icon>
            <v-icon :size="icon_size" @click="dialog_search = true"
              >mdi-magnify
            </v-icon>
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon :size="icon_size">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="modo_editar = !modo_editar">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <h1
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'text-center text-h4 pt-10'
              : 'text-center text-h3 pt-6'
          "
        >
          Proyectos
        </h1>
      </v-col>
    </v-app-bar>
    <br />
    <br />
    <br />
    <br />
    <v-main class="mt-12">
      <v-container>
        <div class="text-center" v-if="empty">
          <v-icon class="mx-auto mt-10" style="opacity: 20%;" size="200"
            >mdi-folder</v-icon
          >
          <h2 style="opacity: 20%;">No hay nada en tu carpeta</h2>
        </div>
        <v-row>
          <v-col
            v-for="(j, index) in project_list"
            :key="`${j.name}`"
            cols="4"
            md="2"
          >
            <div class="text-center">
              <v-icon
                v-touch="{
                  start: () => timer_click_start(),
                  end: () => timer_click_end(),
                }"
                @mousedown="modo_editar ? '' : timer_click_start()"
                @mouseup="
                  modo_editar
                    ? edit_folder_dialog(j._id, j.name)
                    : modo_borrar
                    ? timer_click_end()
                    : go_to_file(j._id, j.name, index)
                "
                :size="folder_size"
                :class="modo_borrar ? 'shake_icon' : 'none'"
                color="blue"
                >mdi-folder</v-icon
              >
              <v-btn icon absolute v-if="modo_borrar" class="mt-4 ml-n4">
                <v-icon
                  color="white"
                  size="30"
                  @click="(id_editar = j._id), (delete_folder_dialog = true)"
                  class="ml-n7 mt-n7"
                  >mdi-close-circle</v-icon
                ></v-btn
              >
              <v-btn icon absolute class="ml-n14 mt-6" v-if="modo_editar">
                <v-icon
                  color="white"
                  @click="edit_folder_dialog(j._id, j.name)"
                  :class="$vuetify.breakpoint.smAndDown ? ' ' : 'ml-n6 mt-6'"
                  >mdi-dots-horizontal</v-icon
                ></v-btn
              >
              <h4
                :class="
                  $vuetify.breakpoint.smAndDown ? 'caption ' : 'subtitle-1 '
                "
              >
                {{ j.name }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-bottom-navigation
      v-if="modo_borrar || modo_editar"
      app
      color="teal"
      grow
    >
    </v-bottom-navigation>
    <div class="text-center">
      <v-btn
        dark
        fab
        fixed
        elevation="24"
        bottom
        v-if="modo_borrar"
        @click="modo_borrar = false"
        class="ml-n7 mb-1"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
      <v-btn
        dark
        fab
        fixed
        elevation="24"
        bottom
        v-if="modo_editar"
        @click="modo_editar = false"
        class="ml-n7 mb-1"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="dialog_agregar" max-width="500px">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-btn absolute left icon class="ml-n3">
          <v-icon size="20" @click="dialog_agregar = false">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="justify-center">
          <span class="text-h5 py-5">Nuevo Proyecto</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid1">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="10">
                <v-text-field
                  required
                  :rules="reglas_proyecto"
                  v-model="project_name"
                  label="Nombre"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-between" class="pa-6">
            <v-btn color="error" @click="dialog_agregar = false">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!valid1"
              color="blue darken-1"
              @click="dialog_agregar = false"
              v-on:click="new_project"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_editar" max-width="500px">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-btn absolute left icon class="ml-n3">
          <v-icon size="20" @click="dialog_editar = false">mdi-close</v-icon>
        </v-btn>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn absolute right icon v-bind="attrs" v-on="on">
              <v-icon class="mt-2 mr-n5">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="delete_folder_dialog = true">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-card-title class="justify-center">
          <span class="text-h5 py-5">{{ nombre_editar }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid1">
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="10">
                <v-text-field
                  required
                  :rules="reglas_proyecto"
                  v-model="nombre_editar"
                  label="Nombre"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="space-between" class="pa-6">
            <v-btn color="error" @click="dialog_editar = false">
              Cancelar
            </v-btn>

            <v-btn
              :disabled="!valid1"
              color="blue darken-1"
              v-on:click="edit_folder"
            >
              Guardar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delete_folder_dialog" width="350">
      <v-card class="text-center" style=" border-radius: 12px;">
        <v-card-title class="text-h4 blue-grey justify-center">
          Eliminar
        </v-card-title>

        <v-card-text class="text-h6">
          ¿Está seguro de que desea eliminar este proyecto?
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="blue darken-1"
            text
            @click="delete_folder_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            style="margin-left: auto; margin-right: auto"
            color="error"
            text
            @click="delete_folder_dialog = false"
            v-on:click="delete_project_tasks"
          >
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_search" width="800">
      <v-card style=" border-radius: 12px;" class="grey lighten-2">
        <v-card-title>
          Buscador
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          class="grey lighten-2"
          :headers="headers"
          :items="project_search_list"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="dialog_search = false"
              v-on:click="edit_folder_dialog(item.id, item.name)"
            >
              <v-icon small class="mr-2">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style scoped>
.shake_icon {
  animation: shake 1s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
<script>
/* eslint-disable */
import axios from "axios";
export default {
  data: () => ({
    modo_borrar: false,
    project_list: [],
    search: "",
    dialog_agregar: false,
    dialog_editar: false,
    delete_folder_dialog: false,
    nombre_editar: "",
    id_editar: "",
    valid1: true,
    dialog_search: false,
    project_search_list: [],
    project_name: "",
    empty: false,
    modo_editar: false,
    reglas_proyecto: [(v) => !!v || "Nombre es requerido"],
    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: true,
        value: "name",
      },

      { text: "Editar", value: "actions", sortable: false },
    ],
  }),
  mounted() {
    this.get_all_projects();
  },
  computed: {
    icon_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 24;
        case "sm":
          return 24;
        case "md":
          return 30;
        case "lg":
          return 30;
        case "xl":
          return 30;
      }
    },
    folder_size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 80;
        case "sm":
          return 80;
        case "md":
          return 100;
        case "lg":
          return 100;
        case "xl":
          return 100;
      }
    },
  },
  methods: {
    go_to_file(x, name, i) {
      var link_ =
        "task_manager/" + x + "?name=" + name + "&aviso=" + 0 + "&list=" + i;
      window.location.replace(link_);
    },
    edit_folder_dialog(id, name) {
      this.dialog_editar = true;
      this.nombre_editar = name;
      this.id_editar = id;
    },
    edit_folder() {
      var vueInstance = this;
      for (let i = 0; i < this.project_list.length; i++) {
        if (this.id_editar == this.project_list[i]._id) {
          this.project_list[i].name = this.nombre_editar;
        }
      }
      let url =
        process.env.VUE_APP_ROOT_URL + "task_folder/update/" + this.id_editar;
      var token = sessionStorage.getItem("token");
      axios({
        method: "PATCH",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: {
          name: this.nombre_editar,
        },
      })
        .then(function(response) {
          vueInstance.dialog_editar = false;
          vueInstance.nombre_editar = "";
          vueInstance.id_editar = "";
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    get_all_projects() {
      Storage.prototype.setObj = function(key, obj) {
        return this.setItem(key, JSON.stringify(obj));
      };
      Storage.prototype.getObj = function(key) {
        return JSON.parse(this.getItem(key));
      };
      let url = process.env.VUE_APP_ROOT_URL + "task_folder/all";
      var token = sessionStorage.getItem("token");
      var key = sessionStorage.getItem("session_key");
      var vueInstance = this;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          console.log(response);
          vueInstance.project_list = response.data.message;
          if (vueInstance.project_list < 1) {
            vueInstance.empty = true;
          } else {
            vueInstance.empty = false;
          }
          vueInstance.project_search_list = [];
          for (let i = 0; i < response.data.count; i++) {
            vueInstance.project_search_list.push({
              name: response.data.message[i].name,
              id: response.data.message[i]._id,
            });
            sessionStorage.setObj("alltask" + i, []);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    new_project() {
      let url = process.env.VUE_APP_ROOT_URL + "task_folder/send";
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "POST",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: {
          name: this.project_name,
        },
      })
        .then(function(response) {
          console.log(response);
          vueInstance.get_all_projects();
          vueInstance.project_name = "nombre";
          vueInstance.dialog_agregar = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    delete_project_tasks() {
      let url = process.env.VUE_APP_ROOT_URL + "task/dump/" + this.id_editar;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "DELETE",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          console.log("Worked");
          vueInstance.delete_project_folder();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    delete_project_folder() {
      for (let i = 0; i < this.project_list.length; i++) {
        if (this.id_editar == this.project_list[i]._id) {
          this.project_list.splice(i, 1);
        }
      }

      let url =
        process.env.VUE_APP_ROOT_URL + "task_folder/dump/one/" + this.id_editar;
      var token = sessionStorage.getItem("token");
      var vueInstance = this;
      axios({
        method: "DELETE",
        url: url,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then(function(response) {
          vueInstance.dialog_editar = false;
          if (vueInstance.project_list < 1) {
            vueInstance.empty = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    timer_click_start() {
      this.set_timeout = setTimeout(
        () => (
          console.log("2 SEGUNODS"), (this.modo_borrar = !this.modo_borrar)
        ),
        2000
      );
    },
    timer_click_end() {
      clearTimeout(this.set_timeout);
    },
  },
};
</script>
