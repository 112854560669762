import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../components/Login.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/forgot',
    name: 'Password',
    component: () => import("../components/Password.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/task_folders',
    name: 'Task_Folders',
    component: () => import("../components/Task_Folders.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/task_manager/:id',
    name: 'Task_manager',
    component: () => import("../components/task_manager.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/email_code',
    name: 'Email_Code',
    component: () => import("../components/Email_Code.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import("../components/Users.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import("../components/Projects.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '/files/:id',
    name: 'Files',
    component: () => import("../components/Files.vue"),
    // meta: {requiresAuth:false}
  },
  {
    path: '*',
    name: '404',
    component: () => import("../components/404.vue"),
    // meta: {requiresAuth:false}
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
